import * as React from "react"
import { SVGProps } from "react"

interface IProps {
    useCurrentColor?: boolean
}

const SearchIconThin = (props: IProps & SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        {...props}
    >
        <path
            stroke={props.useCurrentColor ? 'currentColor' : "#fff"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6.708 12.25a5.542 5.542 0 1 0 0-11.083 5.542 5.542 0 0 0 0 11.083ZM12.833 12.833l-1.166-1.166"
        />
    </svg>
)
export default SearchIconThin
