import * as React from "react"
import { SVGProps } from "react"
const BedroomIconThin = (props: SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M18.64 9.68005H1.44113C0.960795 9.68005 0.571411 10.0694 0.571411 10.5498V14.6566C0.571411 15.137 0.960795 15.5263 1.44113 15.5263H18.64C19.1203 15.5263 19.5097 15.137 19.5097 14.6566V10.5498C19.5097 10.0694 19.1203 9.68005 18.64 9.68005Z" stroke="currentColor" stroke-miterlimit="10" />
        <path d="M8.48237 7.0658H5.80866C5.58648 7.0658 5.40637 7.24591 5.40637 7.46808V7.64865C5.40637 7.87083 5.58648 8.05094 5.80866 8.05094H8.48237C8.70455 8.05094 8.88466 7.87083 8.88466 7.64865V7.46808C8.88466 7.24591 8.70455 7.0658 8.48237 7.0658Z" fill="currentColor" />
        <path d="M14.044 7.0658H11.3703C11.1481 7.0658 10.968 7.24591 10.968 7.46808V7.64865C10.968 7.87083 11.1481 8.05094 11.3703 8.05094H14.044C14.2662 8.05094 14.4463 7.87083 14.4463 7.64865V7.46808C14.4463 7.24591 14.2662 7.0658 14.044 7.0658Z" fill="currentColor" />
        <path d="M15.6074 4.00004H4.47375C4.17583 3.99831 3.8805 4.05525 3.60461 4.16766C3.32871 4.28007 3.07764 4.44574 2.86577 4.65517C2.65389 4.86461 2.48527 5.11371 2.36967 5.38829C2.25407 5.66287 2.1938 5.95753 2.19208 6.25544V9.67945H17.8892V6.2566C17.8876 5.95858 17.8273 5.6638 17.7117 5.38909C17.5962 5.11438 17.4277 4.86511 17.2158 4.65556C17.0039 4.446 16.7528 4.28024 16.4768 4.16776C16.2008 4.05528 15.9054 3.99831 15.6074 4.00004Z" stroke="currentColor" stroke-miterlimit="10" />
        <path d="M4.19434 15.7703V16.7325" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M15.8898 15.7703V16.7325" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
    </svg>
)
export default BedroomIconThin
