import { useState, useEffect, useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';

/**
 * Components
 */
import Master from "../layouts/Master"

/**
 * Utils
*/
import { isLg, getLanguage, generateUrl, getCurrentBreakpoint, getListingTypeLanguage, generateSlug, sendGA, unauthorizedErrorHandler } from '../../utils/Helper';
import { IPropertyTypeNumber } from '../../utils/Types';
import { debounce } from 'lodash'
import DefaultAxios from '../../utils/DefaultAxios';

/**
 * Icons
 */
import SearchIconThin from '../icons/SearchIconThin';
import FurnishIconThin from '../icons/FurnishIconThin';
import BedroomIconThin from '../icons/BedroomIconThin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';

/**
 * Dynamic
 */

const Img = dynamic(() => import('../Img'));
const ReactAutoSuggest = dynamic(() => import('react-autosuggest'))
const StarListingSection = dynamic(() => import('./homepage/StarListingSection'));
const MoreApartmentsSection = dynamic(() => import('./homepage/MoreApartmentsSection'));
const EntrustApartmentSection = dynamic(() => import('./homepage/EntrustApartmentSection'))
const PopularApartmentSection = dynamic(() => import('./homepage/PopularApartmentSection'))
const FindApartmentSection = dynamic(() => import('./homepage/FindApartmentSection'))
const FindYourDreamApartment = dynamic(() => import('./homepage/FindYourDreamApartment'))
const HomepageArticleSection = dynamic(() => import('./homepage/HomepageArticleSection'))
const AskDellaSection = dynamic(() => import('./homepage/AskDellaSection'))


interface IState {
    [key: string]: string | number | null
    search: string
    tp: number // apartemen / rumah
    c: number // sewa / jual
    ltext: string
    lt: string
    lid: number | null
    slug: string | null
    br: string
    fur: string
}

export interface IApartmentPopular {
    id: number;
    name: string;
    slug: string;
    address: string;
    latitude: number;
    longitude: number;
    price_start: number;
    unit_count: number;
    star_count: number;
    lowest_studio_price_per_month: number;
    lowest_one_bedroom_price_per_month: number;
    lowest_two_bedrooms_price_per_month: number;
    lowest_three_bedrooms_price_per_month: number;
    is_featured: number;
    enquiry_count: number;
    primary_image_thumbnail_path: string;
    url: string;
    location: string;
    lowest_price: number | null;
}


export interface HomepageProps {
    langCode: string
    homepageResponse: {
        star_listing: IUnitHomepage[],
        apartment_popular: IApartmentPopular[]
    }
    optionsResponse: IHomepageSuggestion[]
}

export interface IUnitHomepage {
    id: number;
    name: string;
    slug: string;
    code: string;
    apartment_id: number;
    apartment_name: string;
    unit_area: number;
    tower: string;
    service_charge_type: number;
    service_charge: null;
    bedroom: number;
    bathroom: number;
    is_available: number;
    is_available_sell: number;
    category: number;
    is_dollar: number;
    is_negotiable: number;
    is_special_price: boolean;
    is_entrusted_key: number;
    apartment_slug: string;
    floor_string: string;
    photos: Photo[];
    category_name: string;
    is_wishlisted: boolean
    price: number
}

export interface Photo {
    url: string;
    is_primary: number;
    room_type: string;
}


export interface IProjectDeveloper {
    url: string
    desktop_banner: string
    mobile_banner: string
    name: string
    district: string
    area: string
}

export interface IHomepageSuggestion {
    title: string
    locations: ILocation[]
}

interface ILocation {
    ltext: string // name
    lt: string // type
    lid: number // id
    slug: string | null
    district: string | null // buat apartemen
    area: string | null // buat district
}

export default function Index({ langCode, homepageResponse, optionsResponse }: HomepageProps) {
    const lang = getLanguage(langCode)

    const ldJson = [{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Jendela360",
        "alternateName": "Jendela360",
        "url": "https://jendela360.com",
        "logo": "https://assets.jendela360.com/jendela/assets/images/svg/logo.svg",
        "sameAs": [
            "https://www.facebook.com/jendela360/",
            "https://www.instagram.com/jendela360/",
            "https://www.youtube.com/@jendela3606",
            "https://www.linkedin.com/company/jendela360/"
        ]
    }, {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": "Jendela360",
        "image": "https://assets.jendela360.com/jendela/assets/images/svg/logo.svg",
        "description": langCode === 'en' ? "Jendela360 is a leading apartment rental platform in Indonesia. We connect renter and apartment owner with the help of technology such as : 360 Virtual Tour and Payment processing that able to turn payment into installment up to 12x" : "Jendela360 adalah platform penyewaan apartemen terkemuka di Indonesia. Kami menghubungkan penyewa dan pemilik apartemen dengan bantuan teknologi seperti : 360 Virtual Tour dan proses pembayaran yang dapat mengubah pembayaran menjadi cicilan hingga 12x",
        "brand": {
            "@type": "Brand",
            "name": "Jendela360"
        },
        "offers": {
            "@type": "AggregateOffer",
            "url": "",
            "priceCurrency": "IDR",
            "lowPrice": "700000",
            "highPrice": "108000000"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.5",
            "bestRating": "5",
            "worstRating": "1",
            "ratingCount": "278"
        }
    }
    ]

    const [isLoginModalOpen, setLoginModalOpen] = useState(0)
    const [isLoggedIn, setLoggedIn] = useState(false)

    const [isMobile, setIsMobile] = useState(false)
    const [state, setState] = useState<IState>({
        search: '',
        tp: 1,
        c: 0,
        ltext: '',
        lt: '',
        lid: null,
        slug: null,
        br: 'all',
        fur: ''
    })
    const [initialSuggestions, setInitialSuggestions] = useState<IHomepageSuggestion[]>(optionsResponse)
    const [suggestions, setSuggestions] = useState<IHomepageSuggestion[]>(optionsResponse)

    const [isSearchLoading, setIsSearchLoading] = useState(false)

    useEffect(() => {
        setIsMobile(!isLg())
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])




    const fetchList = async (query?: string) => {
        const Axios = (await import('axios')).default

        Axios.get(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/options/list`, {
            params: {
                query,
                listing_type_id: state.tp,
            }
        })
            .then(res => res.data)
            .then(res => {
                const apartmentData = res.filter((data: any) => data.type === 'apartment').map((data: any) => {
                    return {
                        ltext: data.name,
                        lt: 'apat',
                        lid: data.id,
                        slug: `${data.province.toLowerCase().replace(/\s/g, '-')}/${data.area.toLowerCase().replace(/\s/g, '-')}/${data.district.toLowerCase().replace(/\s/g, '-')}/${data.slug}` || null,
                        district: data.district || null,
                        area: data.area || null,
                        url: data.url
                    }
                })
                const districtData = res.filter((data: any) => data.type === 'district').map((data: any) => {
                    return {
                        ltext: data.name,
                        lt: data.type,
                        lid: data.id,
                        slug: `${data.province.toLowerCase().replace(/\s/g, '-')}/${data.area_slug}/${data.slug}` || null,
                        district: data.district || null,
                        area: data.area || null,
                        url: data.url
                    }
                })
                const areaData = res.filter((data: any) => data.type === 'area').map((data: any) => {
                    return {
                        ltext: data.name,
                        lt: data.type,
                        lid: data.id,
                        slug: `${data.province.toLowerCase().replace(/\s/g, '-')}/${data.slug}` || null,
                        district: data.district || null,
                        area: null,
                        url: data.url
                    }
                })
                const provinceData = res.filter((data: any) => data.type === 'province').map((data: any) => {
                    return {
                        ltext: data.name,
                        lt: data.type,
                        lid: data.id,
                        slug: `${data.name.toLowerCase().replace(/\s/g, '-')}` || null,
                        district: data.district || null,
                        area: data.area || null,
                        url: data.url
                    }
                })
                const output = [
                    {
                        title: lang.apartment,
                        locations: apartmentData
                    },
                    {
                        title: 'Area',
                        locations: areaData
                    },
                    {
                        title: 'District',
                        locations: districtData
                    },
                    {
                        title: lang.province,
                        locations: provinceData
                    }
                ].filter(loc => loc.locations.length > 0)

                setInitialSuggestions(output)
                setSuggestions(output.filter(loc => Number(state.tp) !== 1 ? loc.title !== lang.apartment : true))
            })
            .finally(() => {
                setIsSearchLoading(false)
            })
    }

    // Using callback to prevent function get re-rendered
    const debouncedFetchList = useCallback(debounce(fetchList, 300), [state.tp])

    const handleResize = () => {
        setIsMobile(!isLg())
    }

    const handleChangeTab = (c: number) => {
        if (c !== state.c)
            setState(prev => ({
                ...prev,
                c
            }))
    }

    const handleChange = (e: React.ChangeEvent<{ name: string, value: string | number }>) => {
        const name = e.target.name
        const value = e.target.value

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSearchButton = () => {
        // sessionStorage.setItem('homepage-search', JSON.stringify(state))
        const slugSplitted = state.slug?.split('/') || []
        const actualSlug = slugSplitted[slugSplitted?.length - 1]

        let query = []

        if (['apat', 'area', 'district'].includes(state.lt)) {
            if (state.br !== 'all') query.push(`bedroom=${state.br}`)
            if (state.fur !== '') query.push(`furnish=${state.fur}`)

            if (state.lt !== 'apat' && state.c !== 0) {
                query.push(`for=${state.c}`)
            }
        }

        switch (state.lt) {
            case 'apat': {
                window.location.href = generateUrl(langCode, `/${state.c === 0 ? 'sewa' : 'jual'}-apartemen-${actualSlug}?${query.join('&')}`)
                break
            }
            case 'area': {
                window.location.href = generateUrl(langCode, `/apartemen/${actualSlug}${query.length > 0 ? '?' : ''}${query.join('&')}`)
                break
            }
            case 'district': {
                window.location.href = generateUrl(langCode, `/apartemen-${actualSlug}?${query.join('&')}`)
                break
            }
            default: {
                for (let key in state) {
                    if (key === 'search') continue
                    if (state[key] && state[key] !== '' || state[key] === 0) {
                        query.push(`${key}=${state[key]}`)
                    }
                }

                query.push('fh=1')
                window.location.href = generateUrl(langCode, `/search?${query.join('&')}`)
                break
            }
        }
    }

    // useEffect(() => {
    //     /* behaviour : Remove session when reload, and not remove when back from search page */

    //     const [navigationEntry] = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[]

    //     if (navigationEntry && navigationEntry.type === 'reload') {
    //         sessionStorage.removeItem('homepage-search')
    //     } else {
    //         const localState = sessionStorage.getItem('homepage-search')
    //         if (localState) {
    //             if (typeof JSON.parse(localState) === 'object') {
    //                 setState(JSON.parse(localState))
    //             }
    //         }
    //     }
    // }, [])

    // Handle ketika fetch dibutuhkan
    const onSuggestionsFetchRequested = ({ value, reason }: any) => {
        switch (reason) {
            case 'input-changed':
                let pattern = /^[a-zA-Z0-9,\'.\-()\s]+$/
                const inputValue = value.trim().toLowerCase()
                const escapedValue = inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                if (pattern.test(escapedValue) || escapedValue === '') {
                    setIsSearchLoading(true)
                    debouncedFetchList(escapedValue)
                }
                break
            case 'input-focused':
                setSuggestions(initialSuggestions.filter(suggestion => Number(state.tp) !== 1 ? suggestion.title !== lang.apartment : true))
                break
            case 'escape-pressed':
                setIsSearchLoading(true)
                debouncedFetchList()
                break
        }
    }

    // Handle ketika fetch diclear
    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    }

    // Fungsi untuk menentukan cara mendapatkan value dari suggestion
    const getSuggestionValue = (suggestion: ILocation) => suggestion.ltext

    // Handle change
    const handleLocationChange = (e: any, { newValue }: any) => {
        let pattern = /^[a-zA-Z0-9,\'.\-()\s]+$/
        if (pattern.test(newValue) || newValue === '') {
            setState(prev => ({
                ...prev,
                search: newValue
            }))
        }
    }

    // Render suggestion
    const renderSuggestion = (suggestion: ILocation) => {
        return (
            <div className="text-sm">
                {suggestion.ltext}
                {
                    suggestion.area || suggestion.district ?
                        <p className="text-xs text-gray-ab leading-tight mb-1">{`${suggestion.district || ''} ${suggestion.area || ''}`}</p>
                        : null
                }
            </div>
        )
    }

    // Render title (Apartemen | Area)
    const renderSectionTitle = (section: IHomepageSuggestion) => {
        return (
            <strong>
                {section.title}
            </strong>
        )
    }

    // Mendaparkan daftar suggestion dari tiap section (Apartemen | Area)
    const getSectionSuggestions = (section: IHomepageSuggestion) => section.locations

    // Handle suggestion yang terpilih
    const onSuggestionSelected = (e: any, { suggestion }: any) => {
        setState(prev => ({
            ...prev,
            ...suggestion
        }))
        // setRedirect(suggestion.url)

        // if (state.ltext === '') {
        //     if (state.lt === 'apat') {
        //         if (redirect) {
        //             let path = ''
        //             const redirect_arr = redirect.split('/')

        //             if (state.lt === 'apat') {
        //                 const isApat = Number(state.c) === 0
        //                 path = redirect_arr[redirect_arr.length - 1].replace(isApat ? 'jual' : 'sewa', isApat ? 'sewa' : 'jual')
        //             } else {
        //                 path = redirect_arr.slice(3).join('/')
        //             }

        //             window.location.href = generateUrl(langCode, '/' + path)
        //         }
        //     } else {
        //         window.location.href = generateUrl(langCode, `/${Number(state.c) === 0 ? 'sewa' : 'jual'}/${generateSlug(getListingTypeLanguage(lang, state.tp.toString() as IPropertyTypeNumber, 'id'), ' ', '-')}/${suggestion.slug}`)
        //     }
        // }

        // if (state.ltext === '') {
        //     if (Number(state.tp) === 1) {
        //         if (suggestion.url) {
        //             let path = ''
        //             const redirect_arr = suggestion.url.split('/')

        //             if (suggestion.lt === 'apat') {
        //                 const isApat = Number(state.c) === 0
        //                 path = redirect_arr[redirect_arr.length - 1].replace(isApat ? 'jual' : 'sewa', isApat ? 'sewa' : 'jual')
        //             } else {
        //                 path = redirect_arr.slice(3).join('/')
        //             }

        //             window.location.href = generateUrl(langCode, '/' + path)
        //         } else {
        //             let query = []

        //             for (let key in suggestion) {
        //                 if (key === 'search') continue
        //                 if (suggestion[key] && suggestion[key] !== '' || suggestion[key] === 0) {
        //                     query.push(`${key}=${suggestion[key]}`)
        //                 }
        //             }

        //             query.push('fh=1')
        //             window.location.href = generateUrl(langCode, `/search?${query.join('&')}`)
        //         }
        //     } else {
        //         window.location.href = generateUrl(langCode, `/${Number(state.c) === 0 ? 'sewa' : 'jual'}/${generateSlug(getListingTypeLanguage(lang, state.tp.toString() as IPropertyTypeNumber, 'id'), ' ', '-')}/${suggestion.slug}`)
        //     }
        // }


        // if (state.ltext === '') {
        //     window.location.href = generateUrl(langCode, `/${Number(state.c) === 0 ? 'sewa' : 'jual'}/${generateSlug(getListingTypeLanguage(lang, state.tp.toString() as IPropertyTypeNumber, 'id'), ' ', '-')}/${suggestion.slug}`)
        // }
    }

    // Input props untuk AutoSuggest
    const inputProps = {
        placeholder: isMobile ? lang.homepage_search : lang.homepage_search_input,
        value: state.search,
        onChange: handleLocationChange,
    }

    const sendGASearch = () => {
        try {
            sendGA('search', 'search-apt')
        } catch {
            throw 'Error sending GA Cari Apartemen'
        }
        window.location.href = generateUrl(langCode, `/apartemen`)
    }

    const renderSuggestionsContainerOnLoading = ({ containerProps, children, query }: any) => (
        <div {...containerProps}>
            {
                isSearchLoading ?
                    <span className='text-sm'>
                        Loading...
                    </span>
                    :
                    children
            }
        </div>
    )

    const resetSearch = () => {
        const initialLocation = {
            ltext: '',
            lt: '',
            lid: null,
            slug: null,
            district: null,
            area: null,
            url: '',
            search: '' // for input value
        }
        setState(prev => ({
            ...prev,
            ...initialLocation
        }))
    }



    return (
        <Master
            isTransparent={true}
            langCode={langCode}
            lang={lang}
            title={langCode === "id" ?
                `Sewa Apartemen Jakarta & Tangerang Murah | Jendela360`
                // "Sewa Apartemen di Jakarta Murah - 9.728 Daftar Apartemen Jakarta"
                :
                "Rent Apartments in Jakarta & Tangerang. Best Rated Service | Jendela360"
            }
            description={langCode === "id" ?
                "Sewa Apartemen di Jakarta, Tangerang dan Sekitar | ✅ Jaminan Harga Terbaik ✅ Pilihan Terlengkap ✅ Bisa Dicicil & Bayar Bulanan ✅ Layanan 24 Jam"
                :
                "Rent Apartments in Jakarta, Tangerang, and Surrounding Areas | ✅ Best Price Guarantee ✅ Extensive Selection ✅ Installment & Monthly Payment Options ✅ 24/7 Service"
            }
            image="https://assets.jendela360.com/jendela/assets/images/default-thumbnail.jpg"
            url={``}
            withAds
            setIsLoginPage={setLoggedIn}
            isLoginModalOpen={isLoginModalOpen}
            withDella
            customData={{
                ldJson: ldJson
            }}
            newHeaderType='homepage'
            backgroundColor='bg-transparent'
            sidebarZIndex='z-10'
            headerzIndex='z-101'

        >
            {/* <LoginModal /> */}
            <div className={`relative w-full`} style={isMobile ? { aspectRatio: '5/6', minHeight: 500 } : { minHeight: 620 }}>
                {/* <Img
                    src={`/static/images/png/newhomepage.png`}
                    alt='main banner'
                    className='absolute transform scale-120 -translate-y-20 lg:-translate-y-12 2xl:translate-y-10 inset-0 w-full object-cover object-center lg:object-bottom'
                    priority
                /> */}
                <div className="md:hidden">
                    <Img
                        src='https://d2jgpj59n9tcev.cloudfront.net/assets/homepage/banner-mobile.png'
                        alt="main banner"
                        className='absolute inset-0 w-full h-full object-cover object-center md:rounded-b-120px rounded-b-25px'
                        priority
                    />
                </div>
                <div className="md:block hidden">
                    <div
                        style={{
                            backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.3), rgba(0,0,0,0.1), transparent)',
                            width: '100%',
                            height: 100,
                            position: 'absolute',
                            top: 0,
                            zIndex: 1,
                        }}
                    />
                    <Img
                        src='https://d2jgpj59n9tcev.cloudfront.net/assets/homepage/newhomepage.jpg'
                        alt="main banner"
                        className='absolute inset-0 w-full h-full object-cover object-center md:rounded-b-120px rounded-b-25px'
                        priority
                    />
                </div>
                <div className={`container relative lg:pt-64 lg:px-4 pt-nav-mobile `}>
                    <h1 className="lg:px-0 px-1 flex font-bold text-2xl lg:text-50px py-5 text-white text-left" style={{ textShadow: '0px 4px 8px #0000003D' }}>  {!isMobile ? (
                        langCode === 'id' ? (
                            <>
                                Sewa Apartemen <br />
                                Murah dan Mudah
                            </>
                        ) : (
                            <>
                                Rent an Apartment <br />
                                Cheaply and Easily
                            </>
                        )
                    ) : (
                        lang.tagline
                    )}</h1>
                </div>
                <div className="absolute lg:inset-x-auto w-full" style={{ bottom: isMobile ? -180 : -40 }}>
                    <div className='flex flex-col items-center px-5 lg:px-0'>
                        {/* <div className="border-gray-c2 lg:border-0 relative lg:self-start self-center lg:left-8% right-0 left-0">
                            <div className="flex flex-row relative bg-white overflow-hidden rounded-t-2xl">
                                <div className={`py-3 z-5 px-6 text-base capitalize cursor-pointer transition-colors leading-none w-20 ${state.c === 0 ? 'text-white' : 'text-black-48'} `} onClick={() => handleChangeTab(0)}>
                                    {lang.rent}
                                </div>
                                <div className={`py-3 z-5 px-6 text-base capitalize cursor-pointer transition-colors leading-none w-20 ${state.c === 1 ? 'text-white' : 'text-black-48'} `} onClick={() => handleChangeTab(1)}>
                                    {lang['tab-beli']}
                                </div>
                                <div className={`absolute inset-0 right-1/2 transform ${state.c === 0 ? "translate-x-0" : 'translate-x-20'} duration-150 ease-in-out bg-main`} />
                            </div>
                        </div> */}
                        <div className="border-gray-c2 lg:border-0 flex">
                            <div className="flex flex-row bg-white overflow-hidden rounded-t-2xl relative  lg:left-buttonSwitchlg 2xl:left-buttonSwitch left-0">
                                <div className={`py-3 px-6 text-base capitalize cursor-pointer transition-colors leading-none w-20 z-1 ${state.c === 0 ? 'text-white' : 'text-black-48'} `}
                                    onClick={() => handleChangeTab(0)}>
                                    {lang.rent}
                                </div>
                                <div className={`py-3 px-6 text-base capitalize cursor-pointer transition-colors leading-none w-20 z-1 ${state.c === 1 ? 'text-white' : 'text-black-48'} `}
                                    onClick={() => handleChangeTab(1)}>
                                    {lang['tab-beli']}
                                </div>
                                <div className={`absolute inset-y-0 right-1/2 w-20 h-full transform ${state.c === 0 ? "translate-x-0" : 'translate-x-20'} duration-150 ease-in-out bg-main`} />
                            </div>
                        </div>
                        <div className="md:container lg:px-4" style={{ display: isMobile ? 'contents' : undefined }}>
                            <div className="lg:flex lg:flex-row items-end bg-white w-full rounded-2xl p-3 lg:p-18px gap-14px shadow-homecard">
                                {/* <div className="relative mb-2 lg:w-1/5 lg:mb-0 lg:mr-2">
                                <label htmlFor="property_type">Properti Tipe</label>
                                <div>
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-3 text-gray-700">
                                        <FontAwesomeIcon icon='building' width='0' size='sm' />
                                    </div>
                                    <select
                                        className="border w-full pl-10 pr-8 py-2 rounded-md bg-white outline-none cursor-pointer                                                                                                                                                                                                                                                                                                                                                                                                                                                text-sm"
                                        name="tp"
                                        value={state.tp}
                                        onChange={handleChange}
                                        id="property_type"
                                    >
                                        <option value={1}>{lang.apartment}</option>
                                        <option value={2}>{lang.house}</option>
                                        <option value={3}>{lang.land}</option>
                                        <option value={4}>{lang.shophouse}</option>
                                        <option value={5}>{lang.office}</option>
                                        <option value={6}>{lang.warehouse}</option>
                                        <option value={7}>{lang.business_space}</option>
                                    </select>
                                </div>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-700">
                                    <FontAwesomeIcon icon='sort' width='0' size='sm' />
                                </div>
                            </div> */}
                                <div className="mb-3 lg:mb-0 flex-auto lg:bg-white lg:p-0 p-2 bg-gray-F4F4F4 rounded-md lg:rounded-none lg:w-1/3">
                                    <label htmlFor="" className='text-black-48 text-sm w-full truncate whitespace-no-wrap mb-1 lg:block hidden'>{lang.homepage_search}</label>
                                    <div className="relative border border-transparent lg:border-gray-dd rounded-md">
                                        <ReactAutoSuggest
                                            multiSection={true}
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                                            getSuggestionValue={getSuggestionValue as any}
                                            renderSuggestion={renderSuggestion as any}
                                            renderSectionTitle={renderSectionTitle}
                                            getSectionSuggestions={getSectionSuggestions as any}
                                            onSuggestionSelected={onSuggestionSelected}
                                            inputProps={inputProps}
                                            shouldRenderSuggestions={() => true}
                                            renderSuggestionsContainer={renderSuggestionsContainerOnLoading}
                                            renderInputComponent={(inputProps: any) =>
                                                <div className={`flex flex-row items-center rounded-md py-2`}>
                                                    <span className="flex items-center justify-center pl-0 lg:pl-2">
                                                        <SearchIconThin useCurrentColor className='mr-2 text-main' />
                                                    </span>
                                                    <input
                                                        {...inputProps}
                                                        className={`w-4/5 truncate bg-transparent placeholder-#727272text-sm outline-none`}
                                                    />
                                                    {
                                                        state.search ?
                                                            <FontAwesomeIcon
                                                                onClick={resetSearch}
                                                                icon={faTimes}
                                                                className='mr-3 cursor-pointer text-#727272 absolute right-0 top-auto bottom-auto'
                                                            />
                                                            : null
                                                    }
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='mb-3 lg:mb-0 lg:bg-white lg:p-0 p-2 bg-gray-F4F4F4 rounded-md lg:rounded-none flex-auto' style={{ minWidth: 150 }}>
                                    <label htmlFor="" className='text-black-48 text-sm mb-1 lg:block hidden'>{lang.choose_bedroom_type}</label>
                                    <div className="relative flex flex-row items-center border lg:border-gray-dd rounded-md h-10 px-0 border-transparent lg:px-2">
                                        <BedroomIconThin className='mr-2 text-main' />
                                        <select
                                            className="w-4/5 truncate bg-transparent appearance-none py-2 text-#727272 rounded-md outline-none cursor-pointer text-sm"
                                            name="br"
                                            value={state.br === 'all' ? '' : state.br}
                                            onChange={handleChange}
                                            placeholder={isMobile ? lang.choose_bedroom_type : lang.bedroom_type}
                                        >
                                            <option value='all'>{isMobile ? lang.choose_bedroom_type : lang.bedroom_type}</option>
                                            <option value='0'>Studio</option>
                                            <option value='1'>1 Bedroom</option>
                                            <option value='2'>2 Bedrooms</option>
                                            <option value='3'>3+ Bedrooms</option>
                                        </select>
                                        <FontAwesomeIcon icon={faChevronDown} className='mr-2 text-#727272 absolute right-0 top-auto bottom-auto' />
                                    </div>
                                </div>
                                <div className='mb-3 lg:mb-0 lg:bg-white lg:p-0 p-2 bg-gray-F4F4F4 rounded-md lg:rounded-none flex-auto' style={{ minWidth: 150 }}>
                                    <label htmlFor="" className='text-black-48 text-sm mb-1 lg:block hidden'>{lang.choose_furnish_type}</label>
                                    <div className="relative flex flex-row items-center border lg:border-gray-dd rounded-md h-10 px-0 border-transparent lg:px-2">
                                        <FurnishIconThin className='mr-2 text-main' />
                                        <select
                                            className="w-4/5 truncate py-2 bg-transparent appearance-none text-#727272 rounded-md outline-none cursor-pointer                                                                                                                                                                                                                                                                                                                                                                                                                                              text-sm"
                                            name="fur"
                                            value={state.fur}
                                            onChange={handleChange}
                                            placeholder={isMobile ? lang.choose_furnish_type : lang.furnish_type}
                                        >
                                            <option value="">{isMobile ? lang.choose_furnish_type : lang.furnish_type}</option>
                                            <option value="full">Full Furnish</option>
                                            <option value="semi">Semi Furnish</option>
                                            <option value="non">Non Furnish</option>
                                        </select>
                                        <FontAwesomeIcon icon={faChevronDown} className='mr-2 text-#727272 absolute right-0 top-auto bottom-auto' />
                                    </div>
                                </div>
                                <button
                                    className="w-full outline-none whitespace-no-wrap h-10 focus:outline-none hover:bg-main-dark bg-main text-white lg:w-auto px-6 lg:ml-1 rounded-md"
                                    onClick={handleSearchButton}
                                >
                                    {lang.find_apartment}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`lg:px-4 pt-40 lg:pt-12 mt-16 lg:mb-16 mb-10 container`}>
                <StarListingSection
                    lang={lang}
                    langCode={langCode}
                    initialData={homepageResponse.star_listing}
                    isLoggedIn={isLoggedIn}
                    setLoginModalOpen={setLoginModalOpen}
                />
            </div>
            <div className={`lg:mb-16 mb-10 px-4 lg:container`}>
                <EntrustApartmentSection
                    lang={lang}
                    langCode={langCode}
                />
            </div>
            <div className={`lg:mb-16 mb-10 px-4 lg:container`}>
                <PopularApartmentSection
                    lang={lang}
                    langCode={langCode}
                    initialData={homepageResponse.apartment_popular}
                />
            </div>
            <div className={`lg:mb-16 mb-10 px-4 lg:container`}>
                <AskDellaSection
                    lang={lang}
                    langCode={langCode}
                />
            </div>
            <div className={`mb-10 lg:mb-20 lg:px-4 container`}>
                <FindApartmentSection
                    lang={lang}
                    langCode={langCode}
                />
            </div>
            <div className='lg:container lg:px-4 mb-10'>
                <FindYourDreamApartment
                    lang={lang}
                    langCode={langCode}
                    onClickSearch={sendGASearch}
                />
            </div>
            {/* Hide Testimonial */}
            {/* <div className={`${!isMobile ? 'container' : ''}`}>
                <h3 className="pb-2 mx-8 mb-2 text-xl text-center font-semibold tracking-widest">{lang.testimonials}</h3>
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    emulateTouch={false}
                    noMobile={true}
                    className="custom-indicator"
                    perSlides={breakpoint === 'md' ? 2 : isMobile ? undefined : 3}
                    showIndicators={isMobile ? true : false}
                >
                    {testimonials.map(({ img, img_alt, content }, idx) =>
                        <TestimonialCard
                            lang={lang}
                            key={idx}
                            img={img}
                            img_alt={img_alt}
                            content={content}
                        />
                    )}
                </Carousel>
            </div> */}
            <div className='w-full'>
                <HomepageArticleSection
                    lang={lang}
                    langCode={langCode}
                    isMobile={isMobile}
                />
            </div>

            <div className="hidden lg:block bg-blue-f2 border-t border-b border-gray-ee mb-8">
                <MoreApartmentsSection lang={lang} />
            </div>
            <div className="hidden lg:flex flex-col items-center container px-16 lg:px-32 pb-6">
                <p className="mb-10 tracking-widest font-semibold uppercase">{lang.subtitle_payment}</p>
                <div className="w-full relative" style={{ paddingTop: '5.14%' }}>
                    <Img src="https://d2jgpj59n9tcev.cloudfront.net/assets/images/icon/bank-sprite-new.png" alt="payment" className='w-full h-full object-cover' />
                </div>
            </div>
        </Master >
    )
}